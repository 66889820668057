.esders-table {
    width: 100%;

    th {
        font-size: 14px;
        font-weight: 900;
    }
}

.success-snackbar {
    .mdc-snackbar__surface {
        background-color: #4caf50 !important;
        color: white !important;
    }
}

.cdk-drag-preview {
	box-sizing: border-box;
	border-radius: 4px;
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px
				rgba(0, 0, 0, 0.14), 0 3px 14px 2px
				rgba(0, 0, 0, 0.12);

	height: 48px;
	background-color: white;
	display: table;
}

.cdk-drag-preview  {
	flex-grow: 2;
	font-size: 14px;
	padding: 4px;
	vertical-align: middle;
}

.cdk-drag-placeholder {
	background-color: lightgray;
}

.cdk-drag-animating {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging :not(.cdk-drag-placeholder) {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

// td

.mat-mdc-form-field {
	line-height: 1.25 !important;
}

html {
    position: relative;
    font-family: 'Noto Sans', 'Noto Sans SC', sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100%;
}

body {
    position: relative;
    font-family: 'Noto Sans', 'Noto Sans SC', sans-serif;
    font-size: 15px;
    color: $text-color;
    background-color: $background-color;
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100vh;
    line-height: 1.2;
    background-position: bottom !important;
    background-size: cover !important;
    background-repeat: unset !important;
    background-attachment: fixed !important;
	overflow-x: hidden;
}

// Custom Scrollbar
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// Remove default margin
p {
    margin: 0;
}

// Default headlines
h1,
h2,
h3,
h4,
h5,
h6 {
    text-align: center;
    font-family: 'Noto Sans', 'Noto Sans SC', sans-serif !important;
    margin: 0;
    line-height: 1;
}

.mat-body h1,
.mat-body h2,
.mat-body h3,
.mat-body h4,
.mat-body h5,
.mat-body h6 {
    text-align: center;
    font-family: 'Noto Sans', 'Noto Sans SC', sans-serif;
    text-transform: uppercase;
    margin: 0;
    line-height: 1;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

.leaflet-container a {
    color: #555;
}

.download-button {
	cursor: pointer;
	&:hover i {
		color: $primary-color;
	}
	i {
		font-size: 1.5rem;
		transition: 0.2s ease color;
	}
	.spin{
		color: $primary-color;
	}
	.hide {
		display: none;
	}
}

// Default Button
button {
	&:disabled:not([mat-icon-button]) {
		cursor: default;
		background: $button-color-disabled;
	}
    
    position: relative;
    cursor: pointer;
    padding: 10px 15px 10px 15px;
    margin: 0;
    line-height: normal;
    font-weight: bold;
    border: 0;
    color: #fff;
    background: $button-color;
    transition: background 0.2s ease-in-out;

    &:hover:enabled {
        background: $primary-color;
    }

	mat-icon {
		vertical-align: middle !important;
	}
}

button[mat-icon-button] {
    transition: color 0.2s ease-in-out, background 0.2s ease-in-out;

    &:hover {
        color: $primary-color;
        background: none;
    }
}

.icon-link {
	text-decoration: none;
	color: $text-color;
	transition: color .2s;

	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;

	&:hover {
		color: $primary-color;
		cursor: pointer;
	}
}

.center-text-icon {
	display: flex;
	align-items: center;

	mat-icon {
		padding-right: 5px;
	}
}

.pre {
	font-size: 12px;
	white-space: pre-wrap;
}

.hoverable {
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;

	&:hover {
		cursor: pointer;
    }
}

.hoverable-icon {
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;

	&:hover {
        color: $primary-color;
		cursor: pointer;
    }
}

// Card style
.col.card {
    padding: 0rem;
    margin: 1rem;
}

// Icon Animation
.material-icons.spin {
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-form-field .mat-form-field-wrapper .mat-form-field-flex {
	background: white;
}

// Animationen
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

.hide-on-large-only {
    @media #{$large-and-up} {
      display: none !important;
    }
}

.hide-on-med-and-down {
    @media #{$medium-and-down} {
      display: none !important;
    }
}